import React from "react";
import Navigation from "./components/navigation"; 
import "./index.css";
import FooterComponent from "./components/footerComponent";
import { Outlet } from "react-router-dom";

const App: React.FC = () => {
  return (
    <>
    <Navigation />
    <Outlet />
    <FooterComponent />
    </>
  );
};

export default App;
