import React, { useEffect } from 'react';
import { Link } from "react-router-dom";


const FooterComponent: React.FC = () => {
  useEffect(() => {
    // Feather Icons nach dem Rendern ersetzen
    const feather = require('feather-icons');
    feather.replace();
  }, []);
  return (
    <footer className='flex flex-col md:flex-row h-[10vh] mt-4 w-full md:justify-around justify-start md:items-center items-start pl-4'>
        <div>
            <p className='text-white text-xs md:text-sm'>© 2024 Mael Ozelley. All rights reserved</p>
        </div>
        <div className='flex'>
          <Link to="/contact" className="text-white underline text-xs md:text-sm py-2">Contact</Link>
        </div>
        <div className='flex'>
            <a target='_blank' href="https://www.instagram.com/mael4483/"><i className='text-white mr-5' data-feather="instagram"></i></a>
            <a href="test"><i className="text-white mr-5" data-feather="youtube"></i></a>
            <a href="test"><i className='text-white' data-feather="linkedin"></i></a>
        </div>
    </footer>
  );
}

export default FooterComponent;