import React from 'react';
import { getGalleryList } from './components/galleryList';
import GalleryComponent from './components/galleryComponent';

const Gallery: React.FC = () => {
  const gallery = getGalleryList();
  return (
    <div className="">
      <GalleryComponent gallery={gallery}/>
    </div>
  );
}

export default Gallery;