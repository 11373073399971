import Dune from '../img/dune.jpg'
import Bueffel from '../img/bueffel.jpg'

interface Post {
    imgSrc: string;
    imgAlt: string;
    title: string;
    text: string;
    src: string;
    home: boolean;
  }
  
  export const getPostList = (): Post[] => {
    return [
      {
        imgSrc: Dune,
        imgAlt: 'Dune',
        title: 'Marokko',
        text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo',
        src: '/marokko',
        home: true
      },
      {
        imgSrc: Bueffel,
        imgAlt: 'Dune',
        title: 'Tanzania',
        text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo',
        src: '/tanzania',
        home: true
      },
      {
        imgSrc: Bueffel,
        imgAlt: 'Dune',
        title: 'Tanzania',
        text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo',
        src: '/tanzania',
        home: false
      },
      // Weitere Post-Objekte hier hinzufügen
    ];
  };
  