import React from 'react';
import { useState } from 'react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { Field, Label, Switch } from '@headlessui/react'

function classNames(...classes: string[]): string {
  return classes.filter(Boolean).join(' ')
}

export default function Example() {
  const [agreed, setAgreed] = useState(false)

  return (
    <div className="text-center">
      <div className="h-screen flex justify-center">
        <div className="w-full md:w-1/2 xl:w-1/3 flex flex-col self-center">
          <h1 className="text-6xl mb-4">Contact</h1>
          <p className="text-white text-center text-base font-['Arial']">Mael Ozelley</p>
          <p className="text-white text-center text-base font-['Arial']">info@maelozelley.ch</p>
        </div>
      </div>
    </div>
    // <div className="isolate bg-black px-6 py-24 sm:py-32 lg:px-8">
    //   <div className="mx-auto max-w-2xl text-center">
    //     <h1 className="">Contact me</h1>
    //   </div>
    //   <form action="#" method="POST" className="mx-auto mt-16 max-w-xl sm:mt-20">
    //     <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
    //       <div>
    //         <label htmlFor="first-name" className="block text-sm font-semibold leading-6 text-white">
    //           First name
    //         </label>
    //         <div className="mt-2.5">
    //           <input
    //             type="text"
    //             name="first-name"
    //             id="first-name"
    //             autoComplete="given-name"
    //             className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
    //           />
    //         </div>
    //       </div>
    //       <div>
    //         <label htmlFor="last-name" className="block text-sm font-semibold leading-6 text-white">
    //           Last name
    //         </label>
    //         <div className="mt-2.5">
    //           <input
    //             type="text"
    //             name="last-name"
    //             id="last-name"
    //             autoComplete="family-name"
    //             className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
    //           />
    //         </div>
    //       </div>
    //       <div className="sm:col-span-2">
    //         <label htmlFor="company" className="block text-sm font-semibold leading-6 text-white">
    //           Company
    //         </label>
    //         <div className="mt-2.5">
    //           <input
    //             type="text"
    //             name="company"
    //             id="company"
    //             autoComplete="organization"
    //             className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
    //           />
    //         </div>
    //       </div>
    //       <div className="sm:col-span-2">
    //         <label htmlFor="email" className="block text-sm font-semibold leading-6 text-white">
    //           Email
    //         </label>
    //         <div className="mt-2.5">
    //           <input
    //             type="email"
    //             name="email"
    //             id="email"
    //             autoComplete="email"
    //             className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
    //           />
    //         </div>
    //       </div>
    //       <div className="sm:col-span-2">
    //         <label htmlFor="phone-number" className="block text-sm font-semibold leading-6 text-white">
    //           Phone number
    //         </label>
    //         <div className="relative mt-2.5">
    //           <div className="absolute inset-y-0 left-0 flex items-center">
    //             <label htmlFor="country" className="sr-only">
    //               Country
    //             </label>
    //             <select
    //               id="country"
    //               name="country"
    //               className="h-full rounded-md border-0 bg-transparent bg-none py-0 pl-4 pr-9 text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
    //             >
    //               <option>US</option>
    //               <option>CA</option>
    //               <option>EU</option>
    //             </select>
    //             <ChevronDownIcon
    //               className="pointer-events-none absolute right-3 top-0 h-full w-5 text-gray-400"
    //               aria-hidden="true"
    //             />
    //           </div>
    //           <input
    //             type="tel"
    //             name="phone-number"
    //             id="phone-number"
    //             autoComplete="tel"
    //             className="block w-full rounded-md border-0 px-3.5 py-2 pl-20 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
    //           />
    //         </div>
    //       </div>
    //       <div className="sm:col-span-2">
    //         <label htmlFor="message" className="block text-sm font-semibold leading-6 text-white">
    //           Message
    //         </label>
    //         <div className="mt-2.5">
    //           <textarea
    //             name="message"
    //             id="message"
    //             rows={4}
    //             className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
    //             defaultValue={''}
    //           />
    //         </div>
    //       </div>
    //       <Field as="div" className="flex gap-x-4 sm:col-span-2">
    //         <div className="flex h-6 items-center">
    //           <Switch
    //             checked={agreed}
    //             onChange={setAgreed}
    //             className={classNames(
    //               agreed ? 'bg-indigo-600' : 'bg-gray-200',
    //               'flex w-8 flex-none cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
    //             )}
    //           >
    //             <span className="sr-only">Agree to policies</span>
    //             <span
    //               aria-hidden="true"
    //               className={classNames(
    //                 agreed ? 'translate-x-3.5' : 'translate-x-0',
    //                 'h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out'
    //               )}
    //             />
    //           </Switch>
    //         </div>
    //         <Label className="text-sm leading-6 text-gray-600">
    //           By selecting this, you agree to our{' '}
    //           <a href="#" className="font-semibold text-indigo-600">
    //             privacy&nbsp;policy
    //           </a>
    //           .
    //         </Label>
    //       </Field>
    //     </div>
    //     <div className="mt-10">
    //       <button
    //         type="submit"
    //         className="block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
    //       >
    //         Let's talk
    //       </button>
    //     </div>
    //   </form>
    // </div>
  )
}
