import React from "react";
import ProjectPosts from "./components/projectPosts";
import { getPostList } from "./components/postList";
import { Link } from "react-router-dom";



const Projects: React.FC = () => {
  const posts = getPostList();
  return (
    <div className="text-center">
      {/* <div className="h-screen flex justify-center">
        <div className="w-1/3 flex flex-col self-center">
          <h1 className="text-6xl mb-4">Poject Page</h1>
          <p className="text-white text-justify text-base font-['Arial']">
            Discover my passion for photography and videography expressed
            through images. On this Page you can find a selection of my
            projects. Feel free to contact me if you are interested in a
            collaboration.{" "}
          </p>
          <div className="w-full flex justify-center">
          <button className="w-1/3 border-solid border rounded border-white text-white p-1 mt-4 transition-color hover:bg-white duration-500 hover:text-black">
            <Link to="/contact" className="w-full flex justify-center">Contact</Link>
            </button>
          </div>
        </div>
      </div> */}
      <ProjectPosts posts={posts}></ProjectPosts>
    </div>
  );
};

export default Projects;
