import React, { ReactNode } from "react";
import { Link } from "react-router-dom";


interface Post {
  imgSrc: string;
  imgAlt: string;
  title: string;
  text: string;
  src: string;
  home: boolean;
}

interface ProjectPostsProps {
  posts: Post[];
}

const ProjectPosts: React.FC<ProjectPostsProps> = ({ posts }) => {
  return (
    <div>
      {posts.map((posts, index) => (
        <div key={index} className='relative group overflow-hidden'>
          <Link to={posts.src}>
          <img
            src={posts.imgSrc}
            alt={posts.imgAlt}
            className="h-[25vh] md:h-[30vh] xl:h-[50vh] w-full object-cover transition-transform duration-500 group-hover:scale-105"
          />
          <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-500 flex flex-col justify-end p-10">
            <h1 className="text-left pl-10 text-white">{posts.title}</h1>
            <p className="text-white pl-2 xl:pl-10 pb-2 xl:pb-10 text-xs text-left w-full xl:w-1/2 font-['Arial']">
              {posts.text}
            </p>
          </div>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default ProjectPosts;
