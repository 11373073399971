import Goat from '../img/Goat_reduce.webp'
import Goat1 from '../img/Goat2_reduce.webp'
import GoatBig from '../img/GoatBig_reduce.webp'
import Fox from '../img/Fox.jpg'
import Rotmilan from '../img/Rotmilan_reduce.webp'


interface Gallery {
    imgSrc: string;
    imgAlt: string;
    title: string;
    isPortrait: boolean;
    src: string;
  }
  
  export const getGalleryList = (): Gallery[] => {
    return [
      {
        imgSrc: Goat1,
        imgAlt: 'Dune',
        title: 'Marokko',
        isPortrait: false,
        src: '',
      },
      {
        imgSrc: Goat,
        imgAlt: 'Dune',
        title: 'Marokko',
        isPortrait: false,
        src: '',
      },
      {
        imgSrc: GoatBig,
        imgAlt: 'Dune',
        title: 'Tanzania',
         isPortrait: false,
        src: '',
    },
      {
        imgSrc: Fox,
        imgAlt: 'Dune',
        title: 'Tanzania',
         isPortrait: false,
        src: '',
    },
      {
        imgSrc: Rotmilan,
        imgAlt: 'Dune',
        title: 'Tanzania',
         isPortrait: false,
        src: '',
    },
    ];
  };
  