import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import Tanzania from "../projects/Tanzania";
import Marokko from "../projects/Marokko";
import Home from "../home";
import Projects from "../projects";
import Gallery from "../gallery";
import About from "../about";
import Contact from "../contact";

export const router = createBrowserRouter([
    {
    path: "/",
    element: <App />,
    children: [
        {path: "", element: <Home />},
        {path: "projects", element: <Projects />},
        {path: "gallery", element: <Gallery />},
        {path: "about", element: <About />},
        {path: "contact", element: <Contact />},
        {path: "tanzania", element: <Tanzania />},
        {path: "marokko", element: <Marokko />}
    ]
}
]) 