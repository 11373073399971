import { format } from "path";
import React, { ReactNode } from "react";

interface Gallery {
  imgSrc: string;
  imgAlt: string;
  title: string;
  isPortrait: boolean;
  src: string;
}

interface GalleryProps {
  gallery: Gallery[];
}

const GalleryComponent: React.FC<GalleryProps> = ({ gallery }) => {
  return (
    <div className="columns-2 xl:columns-3">
      {gallery.map((gallery, index) => (
        <div key={index} className="mb-4 relative group overflow-hidden">
          <a href={gallery.src}>
          <img
            src={gallery.imgSrc}
            alt={gallery.imgAlt}
            className="w-full object-cover relative group-hover:scale-105 transition-scale duration-500"
          />
          <div className="absolute hidden inset-0 justify-end xl:flex flex-col">
            <h1 className="text-left hidden pl-10 text-white">{gallery.title}</h1>
          </div>
          </a>
        </div>
      ))}
    </div>
  );
};

export default GalleryComponent;
