import React from 'react';

const Marokko: React.FC = () => {
  return (
    <div className="text-center">
      <h1 className="text-4xl text-black">Marokko Page</h1>
      <p className='text-black'>Welcome to the Marokko Page!</p>
    </div>
  );
}

export default Marokko;