import React from "react";
import duneHeader from "./img/duneHeader.png";
import Portrait from "./img/portrait.png";
import ProjectPostsHome from "./components/projectPostsHome";
import { getPostList } from "./components/postList";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { useRef } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";


gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);



const Home: React.FC = () => {
  const posts = getPostList();

  const container = useRef(null);
  useGSAP(() => {
    gsap.fromTo("#about_text, #about_title",{opacity: 0, yPercent: 50}, {duration: 1, yPercent: 0, opacity: 1, stagger:0.1,  scrollTrigger: "#about_text"});
    }, {scope: container});

  return (
    <div>
      <div className="h-screen">
        <img
          className="h-screen object-cover w-full absolute z-10"
          src={duneHeader}
          alt=""
        />
        <div className="absolute w-full h-screen z-20 justify-center content-center text-center">
          <p className="text-white font-bold">Mael Ozelley</p>
          <p className="text-white">Photographie & Filmmaking</p>
        </div>
      </div>

      <div className="h-screen px-10 flex md:flex-row flex-col justify-center">
        <div className="md:w-1/2 w-full max-h-[66vh] xl:max-h-full flex justify-center xl:ml-20 ml-0 xl:my-20 my-2 xl:mr-10 mr-1">
          <img
            className="object-cover object-center"
            src={Portrait}
            alt="Zeigt das Portrait vom Fotografen & Videograf Mael Ozelley"
          />
        </div>
        <div className="md:w-1/2 h-max-[33vh] xl:h-max-full w-full flex justify-center align-center flex-col ml-0 md:ml-5 xl:ml-10  xl:my-20 my-0 xl:mr-20 mr-0" ref={container}>
          <h1 id="about_title" className=" text-left">ABOUT</h1>
          <p id="about_text" className="text-white text-justify text-sm xl:text-base font-['Arial'] test">
            Hi, my name is Mael Ozelley. I am an enthusiastic photographer and
            filmmaker. I discovered photography at an early age. Traveling with
            my parents through different countries, seeing and learning about
            new animals and cultures, inspired me to capture these unique
            moments. Back then I used binoculars and my cell phone, today I use
            my Sony camera. I am currently in my 4th year of apprenticeship as a
            mediamatician.{" "}
          </p>
        </div>
      </div>

      <ProjectPostsHome posts={posts}></ProjectPostsHome>
    </div>
  );
};

export default Home;
