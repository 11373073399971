import React from 'react';

const Tanzania: React.FC = () => {
  return (
    <div className="text-center">
      <h1 className="text-4xl text-black">Tanzania Page</h1>
      <p className='text-black'>Welcome to the Tanzania Page!</p>
    </div>
  );
}

export default Tanzania;