import React from "react";

const About: React.FC = () => {
  return (
    <div className="text-center">
      <div className="h-screen flex justify-center">
        <div className="w-full md:w-1/2 xl:w-1/3 flex flex-col self-center">
          <h1 className="text-6xl mb-4">My Work</h1>
          <p className="text-white text-justify text-base font-['Arial'] mx-8">
              When I'm not out with my camera, I also enjoy designing different things and programming websites.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
